/* You can add global styles to this file, and also import other style files */
@screen-xs: 480px;
@screen-xs-min: @screen-xs;

// Small screen / tablet
@screen-sm: 576px;
@screen-sm-min: @screen-sm;

// Medium screen / desktop
@screen-md: 768px;
@screen-md-min: @screen-md;

// Large screen / wide desktop
@screen-lg: 992px;
@screen-lg-min: @screen-lg;

// Extra large screen / full hd
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;

// Extra extra large screen / large desktop
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;

// provide a maximum
@screen-xs-max: (@screen-sm-min - 1px);
@screen-sm-max: (@screen-md-min - 1px);
@screen-md-max: (@screen-lg-min - 1px);
@screen-lg-max: (@screen-xl-min - 1px);
@screen-xl-max: (@screen-xxl-min - 1px);

.hx-content {
  height: 100%;

  .inner-content {
    padding: 0;
    background: #fff;
    height: 100%;
  }

  @media screen and (min-width: @screen-sm) {
    .inner-content {
      padding: 24px;
    }
  }

  @media screen and (max-width: @screen-sm) {
    .ant-card-body {
      padding: 16px;
    }

    .ant-page-header {
      padding: 8px 12px;
    }
  }
}

.hx-card {
  width: 300px;
  margin: 16px 0;

  .ant-card-actions {
    display: flex;

    li {
      flex: 1 1 0px;
    }
  }
}
/*.hx-org-select nz-select {
  width: 14rem;
}
.hx-city-select nz-select {
  width: 12rem;
}*/

nz-select {
  width: 100%;
  min-width: 10rem;
}

// TODO @deedarb учитывать состояние sidebar'а
.hx-form {
  @col_size: 6rem;
  [nz-col] {
    min-width: @col_size;
  }

  [nz-col].hx-col-1_5 {
    min-width: @col_size*1.5;
  }

  [nz-col].hx-col-2 {
    min-width: @col_size*2;
  }

  [nz-col].hx-col-3 {
    min-width: @col_size*3;
  }

  [nz-col].hx-col-4 {
    min-width: @col_size*4;
  }
}

.hx-mixin-form-col(@col_size, @screen_type) {
  @media screen and (min-width: @screen_type) {
    .hx-form {
      [nz-col] {
        min-width: @col_size;
      }

      [nz-col].hx-col-1_5 {
        min-width: @col_size*1.5;
      }

      [nz-col].hx-col-2 {
        min-width: @col_size*2;
      }

      [nz-col].hx-col-3 {
        min-width: @col_size*3;
      }

      [nz-col].hx-col-4 {
        min-width: @col_size*4;
      }
    }
  }
}

.hx-mixin-form-col(8rem, @screen-xs);
.hx-mixin-form-col(10rem, @screen-sm);
.hx-mixin-form-col(12rem, @screen-md);
.hx-mixin-form-col(14rem, @screen-lg);

.hx-modal-xlg-wrap {
  overflow-y: hidden;

  .ant-modal {
    top: 2%;
    width: 90%;

    .ant-modal-body {
      overflow-y: scroll;
      height: 80vh;
    }

    @media screen and (max-width: @screen-sm) {
      top: 0;
      width: 100%;
      max-width: 100%;
      margin: 0;

      .ant-modal-body {
        height: 84vh;
      }
    }
  }
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: 1rem;
}
